<template>
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.6641 6.66797H5.33073C3.85797 6.66797 2.66406 7.86188 2.66406 9.33464V22.668C2.66406 24.1407 3.85797 25.3346 5.33073 25.3346H26.6641C28.1368 25.3346 29.3307 24.1407 29.3307 22.668V9.33464C29.3307 7.86188 28.1368 6.66797 26.6641 6.66797Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      d="M17 15H27"
      stroke="currentColor"
      stroke-linecap="round"
    />
    <path
      d="M17 18H21"
      stroke="currentColor"
      stroke-linecap="round"
    />
    <path
      d="M23 18H27"
      stroke="currentColor"
      stroke-linecap="round"
    />
    <path
      d="M10.7055 16.6435H6V15.4135H10.6744C11.093 15.4135 11.4307 15.3496 11.6876 15.2218C11.9446 15.09 12.1331 14.9066 12.2533 14.6717C12.3735 14.4368 12.4336 14.1628 12.4336 13.8496C12.4336 13.5694 12.3735 13.3077 12.2533 13.0646C12.1331 12.8214 11.9466 12.6236 11.6939 12.4712C11.4411 12.3146 11.1116 12.2363 10.7055 12.2363H8.82828V20H7.26807V11H10.7055C11.4017 11 11.9943 11.1216 12.4833 11.3647C12.9723 11.6078 13.3473 11.9437 13.6084 12.3723C13.8695 12.7967 14 13.285 14 13.8372C14 14.4265 13.8674 14.9313 13.6022 15.3516C13.3411 15.7679 12.964 16.0872 12.4709 16.3098C11.9819 16.5323 11.3934 16.6435 10.7055 16.6435ZM10.8547 17.3049V18.535H6V17.3049H10.8547Z"
      fill="currentColor"
    />
  </svg>
</template>
