<template>
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10101_37589)">
      <g clip-path="url(#clip1_10101_37589)">
        <g clip-path="url(#clip2_10101_37589)">
          <path
            d="M2 12.2656L3.79234 15.5125V17.493L2.0021 20.7335L2 12.2656Z"
            fill="currentColor"
          />
          <path
            d="M8.88281 14.3317L10.5623 13.2884L13.9995 13.2852L8.88281 16.4619V14.3317Z"
            fill="currentColor"
          />
          <path
            d="M8.87297 12.2459L8.88247 16.5446L7.08594 15.4259V9L8.87297 12.2459Z"
            fill="currentColor"
          />
          <path
            d="M13.9991 13.2839L10.5618 13.2871L8.87297 12.2459L7.08594 9L13.9991 13.2839Z"
            fill="currentColor"
          />
          <path
            d="M8.88247 20.7495V18.6639L7.08594 17.5664L7.08692 23.9987L8.88247 20.7495Z"
            fill="currentColor"
          />
          <path
            d="M10.5571 19.7178L3.79221 15.5125L2 12.2656L13.9912 19.7135L10.5571 19.7178Z"
            fill="currentColor"
          />
          <path
            d="M7.08594 23.9981L8.88124 20.7489L10.5565 19.7152L13.9906 19.7109L7.08594 23.9981Z"
            fill="currentColor"
          />
          <path
            d="M2.00781 20.7308L7.10571 17.5658L5.39182 16.5L3.79805 17.4903L2.00781 20.7308Z"
            fill="currentColor"
          />
          <path
            d="M30.4226 14.6331V18.7893H28.9326V15.8738H27.4978V18.7893H26.0078V14.6328H30.4226V14.6331Z"
            fill="currentColor"
          />
          <path
            clip-rule="evenodd"
            d="M22.947 18.9377C24.281 18.9377 25.2716 18.1231 25.2716 16.8883C25.2716 15.6931 24.5408 14.917 23.3194 14.917C22.7557 14.917 22.2906 15.1147 21.9403 15.4556C22.0239 14.7509 22.6223 14.2363 23.2811 14.2363C23.4332 14.2363 24.5779 14.2339 24.5779 14.2339L25.2251 13C25.2251 13 23.7881 13.0326 23.12 13.0326C21.5935 13.0592 20.5625 14.4412 20.5625 16.1195C20.5625 18.0751 21.5681 18.9378 22.9472 18.9378L22.947 18.9377ZM22.9553 16.0098C23.4505 16.0098 23.7939 16.334 23.7939 16.8882C23.7939 17.3868 23.4888 17.798 22.9553 17.7991C22.445 17.7991 22.1016 17.4183 22.1016 16.8967C22.1016 16.3423 22.445 16.0098 22.9553 16.0098Z"
            fill="currentColor"
            fill-rule="evenodd"
          />
          <path
            d="M19.3492 17.3959C19.3492 17.3959 18.9973 17.5979 18.4716 17.6362C17.8675 17.654 17.3292 17.2739 17.3292 16.5986C17.3292 15.9398 17.8042 15.5623 18.4565 15.5623C18.8565 15.5623 19.3856 15.8384 19.3856 15.8384C19.3856 15.8384 19.7727 15.1308 19.9733 14.7769C19.606 14.4997 19.1169 14.3477 18.548 14.3477C17.1121 14.3477 16 15.2801 16 16.5902C16 17.9171 17.0453 18.8279 18.548 18.8002C18.968 18.7847 19.5474 18.6379 19.9006 18.4119L19.3492 17.3959Z"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_10101_37589">
        <rect
          fill="white"
          height="32"
          width="32"
        />
      </clipPath>
      <clipPath id="clip1_10101_37589">
        <rect
          fill="white"
          height="32"
          width="32"
        />
      </clipPath>
      <clipPath id="clip2_10101_37589">
        <rect
          fill="white"
          height="32"
          width="32"
        />
      </clipPath>
    </defs>
  </svg>
</template>
