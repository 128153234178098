<template>
  <div class="v-container">
    <div class="v-col-12">
      <lazy-cart-messages />
    </div>
    <div class="v-col-12">
      <cart-extra-products />
    </div>
  </div>
  <cart-recommended />
  <div class="v-container">
    <div class="v-cart">
      <div class="v-col-12">
        <cart-gifts-ranged />
      </div>
      <div class="v-col-12">
        <cart-birthday-gift />
      </div>
      <div class="v-col-12">
        <cart-promocode />
      </div>
      <div class="v-col-12 v-text-right">
        <cart-cleanup-button />
      </div>
      <div class="v-col-12">
        <lazy-cart-items-page />
      </div>
      <div class="v-d-flex v-flex-row">
        <div class="v-col-12 v-col-md-8 v-px-xs">
          <lazy-cart-total-summary-page />
        </div>
        <div class="v-col-12 v-col-md-4 v-px-xs">
          <lazy-cart-order-type />
          <lazy-account-saved-addresses-page :can-redact-address="false" />
          <lazy-address-delivery-city-district />
          <lazy-cart-callback />
          <lazy-cart-second-step-button />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
