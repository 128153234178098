<i18n>
ru:
  text: Очистить корзину
ua:
  text: Очистити корзину
us:
  text: Clear cart
</i18n>

<template>
  <arora-button
    v-if="(clientStore.ClientState?.data?.Cart?.Content?.length ?? 0) > 0"
    class-name="v-btn v-btn-link-primary v-btn-text v-no-underline v-font-weight-500"
    ignore-settings
    :label="translate('cleanupButton.text')"
    data-test-id="cart-cleanup-button"
    @click="handleCleanupButtonClick"
  />
</template>

<script setup lang="ts">
const clientStore = useClientStore()
const popupStore = usePopupStore()

const { translate } = useI18nSanitized()

const handleCleanupButtonClick = async (): Promise<void> => {
  await popupStore.showCartCleanup()
}
</script>
