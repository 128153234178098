<template>
  <section>
    <cart-one-stage v-if="appConfig.RestaurantSettingsPreRun.OneStageCart" />
    <cart-two-stage-first v-else />
  </section>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
