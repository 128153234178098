<template>
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="credit-card">
      <path
        id="Vector"
        d="M26.6641 6.66797H5.33073C3.85797 6.66797 2.66406 7.86188 2.66406 9.33464V22.668C2.66406 24.1407 3.85797 25.3346 5.33073 25.3346H26.6641C28.1368 25.3346 29.3307 24.1407 29.3307 22.668V9.33464C29.3307 7.86188 28.1368 6.66797 26.6641 6.66797Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Vector_2"
        d="M4 13.332L28 13.332"
        stroke="currentColor"
        stroke-linecap="square"
        stroke-linejoin="round"
        stroke-width="3"
      />
      <path
        id="Vector 67"
        d="M5.33594 18.668H13.3359"
        stroke="currentColor"
        stroke-linecap="round"
      />
      <path
        id="Vector 68"
        d="M5.33594 21.332H9.33594"
        stroke="currentColor"
        stroke-linecap="round"
      />
    </g>
  </svg>
</template>
