<template>
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.6719 6.66797H5.33854C3.86578 6.66797 2.67188 7.86188 2.67188 9.33464V22.668C2.67188 24.1407 3.86578 25.3346 5.33854 25.3346H26.6719C28.1446 25.3346 29.3385 24.1407 29.3385 22.668V9.33464C29.3385 7.86188 28.1446 6.66797 26.6719 6.66797Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      d="M17 13L28 13"
      stroke="currentColor"
      stroke-linecap="square"
      stroke-linejoin="round"
      stroke-width="3"
    />
    <path
      d="M16 18.668H24"
      stroke="currentColor"
      stroke-linecap="round"
    />
    <path
      d="M16 21.332H20"
      stroke="currentColor"
      stroke-linecap="round"
    />
    <path
      clip-rule="evenodd"
      d="M12.5 10.9625L12.5 20.7042C12.5 21.2357 12.1306 21.6667 11.675 21.6667H6.325C5.86936 21.6667 5.5 21.2357 5.5 20.7042V10.9625C5.5 10.4309 5.86937 10 6.325 10H11.675C12.1306 10 12.5 10.4309 12.5 10.9625ZM12 10.9625C12 10.9432 11.9988 10.9242 11.9964 10.9056C11.9987 10.9238 11.9998 10.9423 11.9998 10.9612L11.9998 20.7029C11.9998 20.9123 11.8543 21.0821 11.6748 21.0821H9.04506C9.29999 21.0554 9.49978 20.8055 9.49978 20.5011C9.49978 20.1789 9.27593 19.9178 8.99978 19.9178C8.72364 19.9178 8.49978 20.1789 8.49978 20.5011C8.49978 20.8055 8.69958 21.0554 8.9545 21.0821H6.32484C6.16166 21.0821 6.02657 20.9418 6.00336 20.7589C6.02611 20.9424 6.16145 21.0833 6.325 21.0833H8.96884C8.97908 21.0841 8.98939 21.0844 8.99978 21.0844C9.01017 21.0844 9.02049 21.0841 9.03072 21.0833H11.675C11.8545 21.0833 12 20.9136 12 20.7042L12 10.9625ZM11.5 11.4928C11.5 11.3133 11.3545 11.1678 11.175 11.1678L6.825 11.1678C6.64551 11.1678 6.5 11.3133 6.5 11.4928L6.5 19.0094C6.5 19.1889 6.64551 19.3344 6.825 19.3344L11.175 19.3344C11.3545 19.3344 11.5 19.1889 11.5 19.0094L11.5 11.4928ZM8.50022 10.2929C8.36215 10.2929 8.25022 10.4235 8.25022 10.5846C8.25022 10.7457 8.36215 10.8763 8.50022 10.8763H9.50022C9.63829 10.8763 9.75022 10.7457 9.75022 10.5846C9.75022 10.4235 9.63829 10.2929 9.50022 10.2929H8.50022Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>
