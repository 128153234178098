<i18n>
ru:
  recommended: Рекомендуемое
ua:
  recommended: Рекомендовано
us:
  recommended: Recommended
</i18n>

<template>
  <div
    v-if="menuStore.Recommended.size > 0"
    class="v-recommended"
  >
    <div class="v-container">
      <div
        class="v-recommended-title"
        v-html="translate('recommended.recommended')"
      />
      <arora-swiper-slider
        :items="recommendations"
        :space-between="20"
        max-items="auto"
        pagination-type="bullets"
      >
        <template #indexedItem="{ item, index }">
          <template v-if="menuStore.Recommended.has(item.ID)">
            <menu-product-in-list-item-card-slider
              v-if="menuStore.Recommended.get(item.ID)?.Product"
              :key="`recommended-product-${index}`"
              is-recommendation
              :product="menuStore.Recommended.get(item.ID)!.Product!"
            />
            <arora-nuxt-link
              v-else-if="menuStore.Recommended.get(item.ID)?.Group"
              :key="`recommended-group-${index}`"
              class-name="v-slider-recommended-group v-pointer"
              open-in-new-tab
              :href="menuStore.Recommended.get(item.ID)!.Group!.Link"
              :label="menuStore.Recommended.get(item.ID)!.Group!.Name"
            >
              <div class="v-mb-md">
                <arora-nuxt-image
                  v-if="
                    menuStore.Recommended.get(item.ID)!.Group!.ImageIcon &&
                    imageExists(menuStore.Recommended.get(item.ID)!.Group!.ImageIcon)
                  "
                  disable-lazy
                  :alt="menuStore.Recommended.get(item.ID)!.Group!.Name"
                  :height="80"
                  :image="menuStore.Recommended.get(item.ID)!.Group!.ImageIcon"
                  :width="80"
                />
                <arora-nuxt-image
                  v-else
                  disable-lazy
                  :alt="menuStore.Recommended.get(item.ID)!.Group!.Name"
                  :height="80"
                  :image="menuStore.Recommended.get(item.ID)!.Group!.Image"
                  :width="80"
                />
              </div>
              <div
                class="v-slider-recommended-group--title"
                v-html="menuStore.Recommended.get(item.ID)!.Group!.Name"
              />
            </arora-nuxt-link>
          </template>
        </template>
      </arora-swiper-slider>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { GUID, HasID } from '@arora/common'

const clientStore = useClientStore()
const menuStore = useMenuStore()

const { imageExists } = useImageInfo()
const { translate } = useI18nSanitized()

const recommendations = computed<HasID[]>(() =>
  (clientStore.ClientState.data?.RecommendationsList ?? []).map((id) => {
    return { ID: id } as HasID
  })
)

watch(
  () => clientStore.ClientState.data?.RecommendationsList,
  async (newContent: GUID[] | undefined, oldContent: GUID[] | undefined) => {
    if (newContent && JSON.stringify(oldContent) !== JSON.stringify(newContent)) {
      await menuStore.updateRecommended(newContent)
    }
  },
  { deep: true }
)

onMounted(async () => {
  if (clientStore.ClientState.state === 'success') {
    await menuStore.updateRecommended(clientStore.ClientState.data?.RecommendationsList ?? [])
  }
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-recommended {
  width: 100%;
  margin-bottom: 10px;
  background: variables.$BodyBackgroundDarken;

  @include mixins.swiper-button-products;

  .swiper-slide {
    width: fit-content;
  }
}

.v-recommended-title {
  font-weight: 600;
  font-size: variables.$TextSizeH2;
  margin-bottom: 10px;
  //todo: после того как переделаем шапку и сладйеры возможно надо будет поиграться с отступами.
  padding: 20px 0;
  @include mixins.sm {
    max-width: calc(100% - 100px);
    flex: 0 0 calc(100% - 100px);
  }
}

.v-slider-recommended-group {
  display: flex;
  flex-direction: column;
  border-radius: variables.$BorderRadius;
  box-shadow: variables.$CardShadow;
  background: variables.$ProductCardBackground;

  width: 100%;
  height: 100%;

  padding: 0 2.215rem 2rem 2.215rem;
  align-items: center;

  &--title {
    color: variables.$ProductCardColor;
    font-size: 1.3rem;
  }
}
</style>
